<template>
	<div id="container">
		<div id="contents">
			<div class="contents_header"></div>

			<div class="contents_body">
				<div class="Search">
					<div class="inner p20">
						<!--기본검색 항목-->
						<div class="select_wrap">
							<select v-model="data.searchDiv" @change="data.mberSeq = data.mberNm = data.corpNm = ''">
								<option value="mberNm">회원명</option>
								<option value="mberSeq">회원번호</option>
							</select>
						</div>
						<input type="text" class="s215 ng-hide" v-if="data.searchDiv == 'mberNm'" v-model="data.mberNm" @keydown="$event.keyCode == 13 && getList()" placeholder="회원명"/>
						<input type="text" class="s215 ng-hide" v-if="data.searchDiv == 'mberSeq'" v-model="data.mberSeq" @keydown="$event.keyCode == 13 && getList()" placeholder="회원번호"/>
						<span class="btn gray" @click="getList()">조회</span>
						<div class="clear">
							<div class="add">
								<div>기술등급(학력기준)</div>
								<div class="select_wrap">
									<select v-model="data.resumeSchoolTechGradeCd" :disabled="data.ncsLevelCd != '' || data.resumeLicenseTechGradeCd != ''" @change="data.ncsLevelCd = data.resumeLicenseTechGradeCd = ''">
										<option value="">전체</option>
										<option v-for="code in commonCode.PRO119" :key="code.code" :value="code.code">
											{{ code.codeNm }}
										</option>
									</select>
								</div>
							</div>
							<div class="add">
								<div>기술등급(자격기준)</div>
								<div class="select_wrap">
									<select v-model="data.resumeLicenseTechGradeCd" :disabled="data.ncsLevelCd != '' || data.resumeSchoolTechGradeCd != ''" @change="data.ncsLevelCd = data.resumeSchoolTechGradeCd = ''">
										<option value="">전체</option>
										<option v-for="code in commonCode.PRO119" :key="code.code" :value="code.code">
											{{ code.codeNm }}
										</option>
									</select>
								</div>
							</div>
							<div class="add">
								<div>NCS Level</div>
								<div class="select_wrap">
									<select v-model="data.ncsLevelCd" :disabled="data.resumeLicenseTechGradeCd != '' || data.resumeSchoolTechGradeCd != ''" @change="data.resumeLicenseTechGradeCd = data.resumeSchoolTechGradeCd = ''">
										<option value="">전체</option>
										<option v-for="code in code_NCS_LEVEL" :key="code.code" :value="code.code">
											{{ code.codeNm }}
										</option>
									</select>
								</div>
							</div>
							<div class="add">
								<div>직무</div>
								<div class="select_wrap">
									<select v-model="data.dutyCd">
										<option value="">전체</option>
										<option v-for="code in commonCode.PRO108" :key="code.code" :value="code.code">
											{{ code.codeNm }}
										</option>
									</select>
								</div>
							</div>
							<div class="add">
								<div>학력</div>
								<div class="select_wrap">
									<select v-model="data.schoolDivCd">
										<option value="">전체</option>
										<option v-for="code in commonCode.PRO107" :key="code.code" :value="code.code">
											{{ code.codeNm }}
										</option>
									</select>
								</div>
							</div>
							<div class="add">
								<div>자격증</div>
								<div class="select_wrap">
									<select v-model="data.licenseCd">
										<option value="">전체</option>
										<option v-for="code in code_LICENSE" :key="code.code" :value="code.code">
											{{ code.codeNm }}
										</option>
									</select>
								</div>
							</div>
							<div class="add">
								<div>경력</div>
								<div class="select_wrap">
									<select v-model="data.totWorkYear">
										<option value="">전체</option>
										<option value="0">0~4년</option>
										<option value="5">5~9년</option>
										<option value="10">10~14년</option>
										<option value="15">15년 이상</option>
									</select>
								</div>
							</div>
							<div class="add">
								<div>나이</div>
								<div class="select_wrap">
									<select v-model="data.ageGroup">
										<option value="">전체</option>
										<option value="20">20대</option>
										<option value="30">30대</option>
										<option value="40">40대</option>
										<option value="50">50대</option>
										<option value="60">60대 이상</option>
									</select>
								</div>
							</div>
						</div>
						<!--//기본검색 항목-->
					</div>
				</div>
				<!--// Search -->

				<div class="Board">
					<div style="float: left; padding: 5px 0">
						※ 평가 : (수행사 평가와 프로엔솔루션 평가의 합계 평균값)
						<span class="A ex"><label class="blind">A</label></span> 매우우수
						<span class="B ex"><label class="blind">B</label></span> 우수
						<span class="C ex"><label class="blind">C</label></span> 보통
						<span class="D ex"><label class="blind">D</label></span> 보통이하
					</div>
					<table class="Board_type1">
						<colgroup>
							<col width="45" /> <!-- No -->
							<col width="50" /> <!-- 평가 -->
							<col width="100" /> <!-- 회원명 -->
							<col width="65" /> <!-- 회원번호 -->
							<col width="65" /> <!-- 직무 -->
							<col width="65" /> <!-- 최종학력 -->
							<col width="90" /> <!-- 경력기간 -->
							<col width="100" /> <!-- 자격증 -->
							<col width="70" /> <!-- 기술등급(학력기준) -->
							<col width="85" /> <!-- 기술등급(자격증기준) -->
							<col width="*" /> <!-- NCS직무/Level -->
							<col width="65" /> <!-- 프로젝트투입여부 -->
							<col width="90" /> <!-- 기술신고서확인날짜 -->
						</colgroup>
						<thead>
							<tr>
								<th>No</th>
								<th>평가<br />등급</th>
								<th>회원명</th>
								<th>회원번호</th>
								<th>직무</th>
								<th>학력</th>
								<th>경력</th>
								<th>자격</th>
								<th>기술등급<br />(학력기준)</th>
								<th>기술등급<br />(자격증기준)</th>
								<th>
									<table class="ncs-level">
										<tr>
											<td class="ncs">NCS 직무</td>
											<td class="level">NCS Level</td>
										</tr>
									</table>
								</th>
								<th>프로젝트<br />투입여부</th>
								<th>기술신고서<br />확인날짜</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in list" :key="item.mberSeq">
								<td>
									{{(pageInfo.pageIndex - 1) * pageInfo.pageSize + (index + 1)}}
								</td>
								<td>
									<span :class="item.totEvalGradeCd"><label class="blind">{{item.totEvalGradeCd}}</label></span>
								</td>
								<td class="over" @click="goSRH002M02(item)">
									<span :class="{ black: item.mgrTargetMberYn == 'Y' }">{{ item.mberNm }}({{item.gender == "M" ? "남" : item.gender == "F" ? "여" : ""}}, {{ item.age }})</span>
									<div style="margin-top: 10px" v-if="item.interestYn == 'Y' || item.interviewStatusCd">
										<span class="paper3" v-if="item.interestYn == 'Y'">관심</span>
										<span class="paper3" v-if="item.interviewStatusCd == '01'">면접요청</span>
										<span class="paper3" v-if="item.interviewStatusCd == '02'">면접중</span>
									</div>
								</td>
								<td class="over" @click="goSRH002M02(item)">
									{{ item.mberSeq }}
								</td>
								<td>{{ item.dutyNm }}</td>
								<td>
									<template v-for="code in commonCode.PRO107">
										<!-- template 에 v-for를 사용할 경우 :key를 쓸수 없고 template의 모든 요소에 key를 넣어야 함 => 그래야 에디터에서 에러 표시가 안됨 -->
										<span :key="code.code" v-if="code.code == item.schoolDivCd">{{ code.codeNm }}</span>
									</template>
								</td>
								<td>{{ item.totWorkMonthCnt }}</td>
								<td>{{ item.licenseNm }}</td>
								<td>
									<template v-for="code in commonCode.PRO119">
										<span :key="code.code" v-if="code.code == item.resumeSchoolTechGradeCd">{{ code.codeNm }}</span>
									</template>
								</td>
								<td>
									<template v-for="code in commonCode.PRO119">
										<span :key="code.code" v-if="code.code == item.resumeLicenseTechGradeCd">{{ code.codeNm }}</span>
									</template>
								</td>
								<td>
									<table class="ncs-level">
										<tr class="line" v-for="ncs in item.ncsGrade.split(',')" :key="ncs.split('|')[0]">
											<td class="ncs">
												<template v-for="code in code_NCS_DETAIL_CLASS">
													<span :key="code.code" v-if="code.code == ncs.split('|')[0]">
														{{ code.codeNm }}
													</span>
												</template>
											</td>
											<td class="level">{{ ncs.split("|")[1] }}</td>
										</tr>
									</table>
								</td>
								<td>{{ item.projectJoinYn == "Y" ? "투입중" : "미투입" }}</td>
								<td>{{ item.resumeGradeAdmApprDate }}</td>
							</tr> <!--// v-for -->
						</tbody>
					</table>
					<div v-if="list.length == 0">
						<td>결과가 없습니다.</td>
					</div>
				</div> <!--// Board -->
			</div> <!--// contents_body -->
		</div> <!--// contents -->

		<!--페이징 컴포넌트 -->
		<pagingComp :pageInfo="pageInfo" @page-click="goPage" />
		<!--// 페이징 컴포넌트 -->
	</div> <!--//container-->
</template>

<script>
import pagingComp from "@/components/PagingComp.vue";

export default {
	components: {
		pagingComp,
	},

	data() {
		return {
			list: [],
			pageInfo: {},

			pageIndex: 1,

			commonCode: {},

			code_NCS_LEVEL: [], // NCS 레벨코드
			code_LICENSE: [], // 자격증 코드
			code_NCS_DETAIL_CLASS: [], // NCS 직무코드

			data: {
				mberSeq: "", // 회원번호
				mberNm: "", // 회원명
				corpNm: "", // 회사명

				searchDiv: "mberNm", // 검색구분 (디폴트는 회원명)
				resumeSchoolTechGradeCd: "", // 기술등급(학력기준) 코드
				resumeLicenseTechGradeCd: "", // 기술등급(자격기준) 코드
				ncsLevelCd: "", // NCS 레벨코드
				dutyCd: "", // 직무코드
				schoolDivCd: "", // 학력코드
				licenseCd: "", // 자격증코드
				totWorkYear: "", // 경력코드
				ageGroup: "", // 나이 그룹코드
			},
		};
	},

	beforeCreate() {},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
	},
	mounted() {
		this.$store.dispatch("commonCode").then((res) => {
			this.commonCode = res;
		});
		this.$store.dispatch("ncsLevelCode").then((res) => {
			this.code_NCS_LEVEL = res;
		});
		this.$store.dispatch("licenseCode").then((res) => {
			this.code_LICENSE = res;
		});
		this.$store.dispatch("ncsDetailClassCode").then((res) => {
			this.code_NCS_DETAIL_CLASS = res;
		});

		this.getList();
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('BBS001M01 update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {
		getList() {
			this.$.httpPost("/api/srh/SRH002M01", this.data)
				.then((res) => {
					//console.log(res.data);

					this.list = res.data.list;
					this.pageInfo = res.data.pageInfo;
				})
				.catch((err) => {
					alert(err.response.data.error_description);
				});
		},

		goSRH002M02(item) {
			alert(item.mberNm);
		},

		goPage(page) {
			// console.log(page);
			this.pageIndex = page;
			this.getBbsList();
		},
	},
};
</script>